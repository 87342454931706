<template>
  <VApp>
    <div
      class="layout"
      :style="{
        '--mainColor': color?.main,
        '--promo': color?.promo,
        '--btn': color?.form_btn,
        '--offer': color?.bg_offer,
        '--form': color?.bg_form,
        '--form2': color?.bg_form2,
        '--form3': color?.bg_form3,
        '--font': color?.font,
        '--font2': color?.font2,
        '--font3': color?.font3,
        '--fontLink': color?.font_link,
        '--consult': color?.consult,
        '--price': color?.price,
      }"
    >
      <LazyDHeaderPartner />
      <slot />
      <LazyDFooterPartner />
      <LazyVDialog
        v-model="showDialogCity"
        :fullscreen="!disabledPortal"
        :width="disabledPortal ? 'auto' : ''"
      >
        <LazyModalCityCheck
          @changed-city="closeMenu('showDialogCity')"
          @close="closeMenu('showDialogCity')"
        />
      </LazyVDialog>
      <LazyVDialog
        v-model="showDialogAddress"
        :fullscreen="!disabledPortal"
        :width="disabledPortal ? 'auto' : ''"
      >
        <LazyModalAddressCheck
          @close="closeMenu('showDialogAddress')"
        />
      </LazyVDialog>
      <LazyVDialog
        v-model="showDialogCall"
        :content-class="!disabledPortal ? 'half-modal' : undefined"
        :width="disabledPortal ? 'auto' : ''"
      >
        <LazyModalCallMeSupport
          :title="btn60Show ? '' : callDialogTitle"
          @close="closeMenu('showDialogCall')"
        />
      </LazyVDialog>
      <LazyVDialog
        v-model="showDialogCallPartner"
        :fullscreen="!disabledPortal && btn60Show"
        :width="disabledPortal ? 'auto' : ''"
        :content-class="!disabledPortal && !btn60Show ? 'half-modal' : undefined"
      >
        <LazyModalCallMeSupport
          v-if="btn60Show"
          @close="closeMenu('showDialogCall')"
        />
        <LazyModalCallMeSupportOld
          v-else
          @close="closeMenu('showDialogCall')"
        />
      </LazyVDialog>
      <ClientOnly>
        <NuxtLoadingIndicator
          ref="indicator"
          :color="color?.promo"
        />
      </ClientOnly>
    </div>
  </VApp>
</template>

<script setup lang="ts">
import { VApp } from 'vuetify/components/VApp'
import { useMainStore } from '~/store/main/main.store'
import { useTariffs } from '~/store/tariffs/tariffs.store'
import { useCities } from '~/store/cites/cities.store'
import { debounce } from '~/helpers'
import { providersComposible } from '~/composible/getProviders'

const ctx = useNuxtApp()
const mainStore = useMainStore()
const provider = computed(() => mainStore.getDomainConfig)
const $route = useRoute()
const tariffsStore = useTariffs()
const cityStore = useCities()
const btn60Show = computed(() => mainStore.getBtn60Show)

const currentCity = computed(() => cityStore.getCity)
const houseUrl = computed(() => cityStore.getHouseUrl)
const metrikaId = computed(() => mainStore.getMetrikaId)
const uaId = computed(() => mainStore.getUaId)
const providerId = computed(() => mainStore.getProviderId)
const utmArr = computed(() => mainStore.getUtmArr)
const houseType = computed(() => cityStore.getHouseType)
const disabledPortal = computed(() => mainStore.disabledPortal)
const $gtm = useGtm()
const callDialogTitle = ref('')
const showDialogCity = ref(false)
const showDialogAddress = ref(false)
const showDialogCall = ref(false)
const showDialogCallPartner = ref(false)
const indicator = ref()
const cityFromCookie = useCookie('currentCity')
const addressFromCookie = useCookie('currentAddress')
const utmArrCookie = useCookie('utmArr')

const sendGtmEvent = debounce(
  (event: any, category: any, action: any, label?: any) => {
    if ($gtm) {
      $gtm.trackEvent({
        event,
        category,
        action,
        label,
      })
    }
  },
  300,
)

const providers = ref()

const { fetchProvidersList } = providersComposible()

const sendGtm = debounce(() => {
  if ($gtm) {
    $gtm.trackEvent({
      event: 'addressCheck',
      providers: providers.value.map((el) => {
        return {
          slug: el.provider.slug,
          id: el.provider.id,
          name: el.provider.name,
        }
      }),
      houseType: houseType.value,
      providersAlt: providers.value
        .map(el => el.provider.slug)
        .join(' '),
      providersCount: providers.value.length,
    })
  }
}, 300)

const sendGtmCall = debounce((action, label?) => {
  if ($gtm) {
    $gtm.trackEvent({
      event: 'trackEvent',
      category: 'call',
      action,
      label,
    })
  }
}, 300)

const closeMenu = (val) => {
  if (val === 'showDialogCity') showDialogCity.value = false
  else if (val === 'showDialogAddress') showDialogAddress.value = false
  else if (val === 'showDialogCall') {
    showDialogCall.value = false
    showDialogCallPartner.value = false
  }
}

const color = computed(() => {
  if (provider.value?.provider.config?.color)
    return provider.value?.provider.config?.color
})

watch(
  () => houseUrl.value,
  async () => {
    if (houseUrl.value) {
      providers.value = await fetchProvidersList()
      sendGtm()
    }
  },
)

const changeMenu = () => {
  mainStore.$patch({
    disabledPortal: window.innerWidth > 1000,
    disabledMobile: window.innerWidth > 767,
  })
}

onBeforeMount(async () => {
  mainStore.fetchCallSlots()
  nextTick(() => {
    let recentSearch = localStorage.getItem('recentSearch')
    if (recentSearch) {
      recentSearch = JSON.parse(recentSearch)?.filter(el => el.selecetedHouse)
      tariffsStore.$patch({
        recentSearch: recentSearch,
      })
    }
  })
  if ($route.fullPath.toString().includes('utm')) {
    const queries = new URLSearchParams($route.fullPath.split('?')[1])
    const utmArr = [] as any[]
    for (const query of queries.entries()) {
      if (query[0].includes('utm')) {
        utmArr.push(query)
      }
    }
    if (utmArr.length) {
      mainStore.$patch({
        utmArr,
      })
    }
  }
})
const loadListen = () => {
  ctx.$listen('gtmSendEvent', (val: any) => {
    sendGtmEvent(val.event, val.category, val.action, val.label)
  })
  ctx.$listen('cityDialog', (val: { redirect: boolean, label: string }) => {
    sendGtmEvent('trackEvent', 'address', 'map-open', val.label)
    showDialogCity.value = true
  })
  ctx.$listen('addressDialog', (val: { redirect: boolean, label: string }) => {
    sendGtmEvent('trackEvent', 'address', 'open', val.label)
    showDialogAddress.value = true
  })
  ctx.$listen('callDialog', (val: string) => {
    callDialogTitle.value = val
    showDialogCall.value = true
  })
  ctx.$listen('callDialogPartner', (val) => {
    showDialogCallPartner.value = val
  })
  ctx.$listen('gtmSendCall', (val) => {
    sendGtmCall(val.action, val.label)
  })
}

onMounted(() => {
  loadListen()
  if (window) {
    changeMenu()
    window.addEventListener('resize', changeMenu)
  }
  if (ctx?.$device?.isIos) {
    preloadComponents([
      'ModalAddressCheck',
      'ModalCityCheck',
      'ModalCallMe',
    ])
  }

  cityFromCookie.value = currentCity.value?.fias_id
  utmArrCookie.value = utmArr.value

  if (houseUrl.value) addressFromCookie.value = houseUrl.value
  else addressFromCookie.value = null
})

onBeforeUnmount(() => {
  ctx.$unlisten('addressDialog')
  ctx.$unlisten('cityDialog')
  ctx.$unlisten('callDialog')
  ctx.$unlisten('callDialogPartner')
  ctx.$unlisten('gtmSendCall')
  ctx.$unlisten('gtmSendEvent')
  if (window) {
    window.removeEventListener('resize', changeMenu)
  }
})

useHead(() => ({
  title: provider.value?.provider?.name,
  link: [
    {
      rel: 'canonical',
      href: () =>
        provider.value ? `https://${provider.value?.domain}${$route.path}` : '',
    },
    // {rel: 'manifest', href: `/manifest.json` },
    {
      hid: 'apple-touch-icon',
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: '/apple-icon-180x180.png',
    },
    {
      rel: 'mask-icon',
      hid: 'mask-icon',
      purpose: 'maskable any',
      href: '/safari-pinned-tab.svg',
      color: '#5bbad5',
    },
    { rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicon-16x16.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicon-32x32.png',
    },
  ],
  meta: [
    { name: 'apple-mobile-web-app-title', content: provider.value?.name },
    { name: 'application-name', content: provider.value?.name },
    {
      name: 'msapplication-TileColor',
      content: provider.value?.provider.config?.color?.main,
    },
    { name: 'msapplication-TileImage', content: '/ms-icon-144x144.png' },
    { hid: 'og:type', property: 'og:type', content: 'product' },
    { hid: 'og:locale', property: 'og:locale', content: 'ru_RU' },
    {
      hid: 'og:site_name',
      property: 'og:site_name',
      content: () =>
        provider.value ? `${provider.value?.domain}` : 'dominternet.ru',
    },
    { hid: 'og:url', property: 'og:url', content: $route.path },
  ],
  script: [
    {
      hid: 'gtm',
      children: `(function (w, d, l) {
        w[l] = w[l] || [];
        const cookie = d.cookie.split('; ')
        const fias_id = '${currentCity.value?.fias_id || ``}'
        const page_version = d.cookie.includes('ab_test') ? d.cookie.split('; ').find(el => el.includes('ab_test')).replace('ab_test=', '') : ''
        const metrika_id = '${metrikaId.value || ``}'
        const ua_id = '${uaId.value || ``}'
        const provider_id = '${providerId.value || ``}'
        w[l].push({
          originalLocation: d.location.protocol + '//' +
            d.location.hostname +
            d.location.pathname +
            d.location.search,
          fias_id,
          page_version,
          metrika_id,
          ua_id,
          provider_id,
        });
      })(window, document, 'dataLayer')`,
      type: 'text/javascript',
    },
    {
      children: () => `{
          "@context": "http://schema.org",
          "@type": "Organization",
          "url": "${
  provider.value
    ? `https://${provider.value?.domain}${$route.path}`
    : 'https://dominternet.ru' + $route.path
  }",
          "logo": "${
  provider.value
    ? `https://${provider.value?.domain}/android-icon-192x192.png`
    : 'https://dominternet.ru/android-icon-192x192.png'
  }"
          }`,
      type: () => 'application/ld+json',
    },
  ],
}))
</script>

<style scoped lang="scss">
</style>
